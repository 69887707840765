@import "./scss/GoogleOauthButton.css";
@import "./scss/bootstrap_util.css";
 .App {
	 min-height: 100vh;
}
 table.fridges-table {
	 width: 100%;
}
 table.fridges-table td {
	 text-align: center;
}
 table.fridges-table td.action {
	 text-align: left;
}
 .text-align-center {
	 text-align: center;
}
 .invisible {
	 visibility: hidden !important;
}
 .App-link {
	 color: #61dafb;
}
 .hidden {
	 display: none !important;
}
 .job-row {
	 border-bottom: 1px solid rgba(108, 117, 125, 0.25);
}
 .muhtable {
	 width: 100%;
}
 .muhtable th {
	 font-weight: 500;
}
 .muhtable th, .muhtable td {
	 padding: 2px;
	 text-align: center;
}
 .My-Mui-Button-root {
	 color: rgba(0, 0, 0, 0.87);
	 padding: 6px 16px;
	 font-size: 0.875rem;
	 min-width: 40px;
	 box-sizing: border-box;
	 transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	 font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	 font-weight: 500;
	 line-height: 1.75;
	 border-radius: 4px;
	 letter-spacing: 0.02857em;
	 text-transform: uppercase;
}
 .My-Mui-ButtonBase-root {
	 color: inherit;
	 border: 0;
	 cursor: pointer;
	 margin: 0;
	 display: inline-flex;
	 outline: 0;
	 padding: 0;
	 position: relative;
	 align-items: center;
	 user-select: none;
	 border-radius: 0;
	 vertical-align: middle;
	 -moz-appearance: none;
	 justify-content: center;
	 text-decoration: none;
	 background-color: transparent;
	 -webkit-appearance: none;
	 -webkit-tap-highlight-color: transparent;
}
 .piggy-span {
	 width: 25px;
}
 body.darkmode .piggy-span path {
	 fill: #fafafa;
}
 body.darkmode .file-cover-btn {
	 background: #424242;
}
 body.lightmode .piggy-span path {
	 fill: rgba(0, 0, 0, 0.54);
}
 body.lightmode .file-cover-btn {
	 background: #fafafa;
}
 .list-group-item {
	 border-bottom: 1px solid rgba(108, 117, 125, 0.25);
}
 .MuiToolbar-gutters {
	 padding: 0 !important;
}
 .italic {
	 font-style: italic;
}
 .bold {
	 font-weight: bold;
}
 .ft12 {
	 font-size: 12px;
}
 .uppercase {
	 text-transform: uppercase;
}
 .center-all {
	 display: flex;
	 justify-content: center;
	 align-items: center;
}
 